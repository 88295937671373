import {
  Brand,
  Heading,
  Identity,
  MainNavBar,
  MenuGroup,
  MenuItem,
  NavLink,
  NavMenu,
  StyleguideProvider,
  UserMenu,
  VerifiedLogo,
} from "@verified/backoffice-styleguide";
import {
  RouterService,
  useFromStream,
  useIdentity,
} from "@verified/sspa-core-lib";

export function TopNav(props) {
  const [session] = useIdentity();
  const [routes] = useFromStream(RouterService.getInstance().routes$, []);

  const userExtras: any = [];

  console.log(session);
  if (session.user) {
    const fullName = session.user.givenName + " " + session.user.familyName;
    userExtras.push(<Identity key="avatar" user={session.user} />);

    userExtras.push(
      <NavMenu key="nav">
        {routes.map((r, index) => (
          <NavLink href={"#/" + r.name} key={index}>
            {r.name}
          </NavLink>
        ))}
      </NavMenu>
    );

    userExtras.push(
      <UserMenu key="userMenu">
        <MenuGroup title={fullName}>
          <MenuItem>Profile</MenuItem>
          <MenuItem>Logout</MenuItem>
        </MenuGroup>
        <MenuItem>User Link 2</MenuItem>
      </UserMenu>
    );
  }

  return (
    <StyleguideProvider>
      <MainNavBar>
        <Brand>
          <VerifiedLogo width="100%" height="100%" />
          <Heading>Backoffice</Heading>
        </Brand>
        {userExtras}
      </MainNavBar>
    </StyleguideProvider>
  );
}
